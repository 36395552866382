<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">

      <v-row>
        <v-col>
          <v-btn
            color="success"
            class="mr-2"
            dark
            @click="openDialog()"
          >
            <v-icon
              left
              v-text="'mdi-plus'"
            />
            Adicionar
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="surveys"
              :options.sync="pagination"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 75]
              }"
              @update:options="loadSurveys"
            >
              <template
                v-slot:[`item.startDate`]="{ item }"
                class="text-right"
              >
                {{ item.startDate | date('DD/MM/YYYY') }}
              </template>

              <template
                v-slot:[`item.endDate`]="{ item }"
                class="text-right"
              >
                <span v-if="item.endDate">
                  {{ item.endDate | date('DD/MM/YYYY') }}
                </span>
                <span v-else>
                  -
                </span>
              </template>

              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  :to="`/pesquisas/${item.id}/respostas`"
                >
                  <v-icon
                    v-text="'mdi-head-question'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                  :loading="loadingDownloadCsv === item.id"
                  @click="downloadCsvAnswers(item)"
                >
                  <v-icon
                    v-text="'mdi-download'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="openDialogSendSms(item)"
                >
                  <v-icon
                    v-text="'mdi-message-text'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                  :to="`/responder-pesquisa/${item.id}`"
                  target="_blank"
                >
                  <v-icon
                    v-text="'mdi-link-variant'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                  :to="`/pesquisas/${item.id}/perguntas`"
                >
                  <v-icon
                    small
                    v-text="'mdi-chat-question'"
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="openDialog(item)"
                >
                  <v-icon
                    small
                    v-text="'mdi-pencil'"
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                >
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDeleteItem(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-survey
      v-model="showDialogSurvey"
      :survey-id="editId"
      @save="loadSurveys"
    />

    <dialog-send-sms
      v-model="showDialogSendSms"
      :survey="selectedSurveyForSms"
      @send="loadSurveys"
    />

    <dialog-delete
      v-model="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />

  </div>
</template>

<script>
  import surveysApi from '@/api/surveys'
  import downloadBase64File from '@/utils/download-base64-file'

  export default {

    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogSurvey: () => import('@/components/dialog/surveys/DialogSurvey'),
      DialogSendSms: () => import('@/components/dialog/surveys/DialogSurveySendSms'),
    },

    data () {
      return {
        loading: false,
        search: null,
        count: 0,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Título', value: 'title' },
          { text: 'Data início', value: 'startDate' },
          { text: 'Data final', value: 'endDate' },
          { text: '', value: 'actions', align: 'right' },
        ],
        surveys: [],
        pagination: {},
        showDialogDelete: false,
        showDialogSurvey: false,
        showDialogSendSms: false,
        selectedSurveyForSms: null,
        loadingDelete: false,
        loadingDownloadCsv: null,
        deleteId: null,
        editId: null,
      }
    },

    methods: {

      async loadSurveys () {
        try {
          this.loading = true

          const response = await surveysApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.surveys = response.data.surveys
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (survey = null) {
        this.editId = survey ? survey.id : null
        this.showDialogSurvey = true
      },

      openDialogSendSms (survey = null) {
        this.showDialogSendSms = true
        this.selectedSurveyForSms = survey
      },

      async clickDeleteItem (survey) {
        this.deleteId = survey.id
        this.showDialogDelete = true
      },

      async downloadCsvAnswers (survey) {
        try {
          this.loadingDownloadCsv = survey.id

          const response = await surveysApi.downloadCsvAnswers(survey.id)

          downloadBase64File(response.data.file, `respostas_pesquisa_${survey.id}.csv`)

          if (response.data?.tableFiles.length > 0) {
            for (const table of response.data.tableFiles) {
              downloadBase64File(table.file, `respostas_pesquisa_${survey.id}_tabela_${table.questionId}.csv`)
            }
          }
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDownloadCsv = null
        }
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await surveysApi.delete(this.deleteId)

          this.loadSurveys()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.showDialogDelete = false
        }
      },

    },

  }
</script>
